/*-----------------------------------------------------------------------------------

    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

01. Theme Default Css
02. BS Grid css
03. buttons css
04. modal css
05. section-title css
06. preloader css
07. pogress css
08. cursor css
09. header css
10. hero css
11. service css
12. about css
13. portfolio css
14. testimonials css
15. client css
16. blog css
17. contact css
18. footer css


**********************************************/
/*----------------------------------------*/
/*  01. TTheme Default Css Start
/*----------------------------------------*/

:root {
  /** @font family declaration */
  --cf-ff-body: 'Rubik', sans-serif;
  --cf-ff-heading: 'Poppins', sans-serif;
  --cf-ff-subheading: 'Poppins', sans-serif;
  --cf-ff-p: 'Rubik', sans-serif;
  --cf-ff-fontawesome: "Font Awesome 6 Pro";
  /** @color declaration */
  --cf-theme-primary: #4261ab;
  --cf-theme-secondary: #ED6D1D;
  --cf-theme-purple: #150b2f;
  --cf-theme-white: #ffffff;
  --cf-theme-dark: #212025;
  --cf-theme-hero-bg: #212025;
  --cf-grey-dark: #212121;
  --cf-grey-light: #a6a6a6;
  --cf-text-body: #9f9f9f;
  --cf-text-1: #000;
  --cf-border-1: #f7f7f7;
  /** @font weight declaration */
  --cf-fw-normal: normal;
  --cf-fw-elight: 200;
  --cf-fw-light: 300;
  --cf-fw-regular: 400;
  --cf-fw-medium: 500;
  --cf-fw-sbold: 600;
  --cf-fw-bold: 700;
  --cf-fw-ebold: 800;
  --cf-fw-black: 900;
  /** @font size declaration */
  --cf-fs-body: 18px;
  --cf-fs-p: 18px;
  --cf-fs-h1: 72px;
  --cf-fs-h2: 48px;
  --cf-fs-h3: 24px;
  --cf-fs-h4: 22px;
  --cf-fs-h5: 18px;
  --cf-fs-h6: 14px;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

/*---------------------------------
    typography css start 
---------------------------------*/
body {
  font-family: var(--cf-ff-body);
  font-size: var(--cf-fs-body);
  font-weight: normal;
  color: var(--cf-text-body);
  line-height: 1.5;
  zoom: 1;
}

a {
  text-decoration: none;
}

h1,
h2,
h5,
h6 {
  font-family: var(--cf-ff-heading);
  color: var(--cf-heading-primary);
  margin-top: 0px;
  font-weight: var(--cf-fw-sbold);
  line-height: 1.2;
}

h4,
h3 {
  font-weight: var(--cf-fw-medium);
}

h1 {
  font-size: var(--cf-fs-h1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 60px;
    line-height: 1.3;
  }
}

@media (max-width: 575px),
only screen and (min-width: 576px) and (max-width: 767px) {
  h1 {
    font-size: 50px;
    line-height: 1.3;
  }
}

h2 {
  font-size: var(--cf-fs-h2);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 36px;
    line-height: 1.3;
  }
}

@media (max-width: 575px) {
  h2 {
    font-size: 26px;
    line-height: 1.3;
  }
}

h3 {
  font-size: var(--cf-fs-h3);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  h3 {
    font-size: 22px;
  }
}

h4 {
  font-size: var(--cf-fs-h4);
}

h5 {
  font-size: var(--cf-fs-h5);
}

h6 {
  font-size: var(--cf-fs-h6);
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

p {
  font-family: var(--cf-ff-p);
  font-size: var(--cf-fs-p);
  font-weight: var(--cf-fw-normal);
  color: var(--cf-text-body);
  margin-bottom: 15px;
  line-height: 27px;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
  border: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input,
textarea {
  outline: none;
}

input[type=color] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--cf-common-black);
  color: var(--cf-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--cf-common-black);
  color: var(--cf-common-white);
  text-shadow: none;
}

::selection {
  background: var(--cf-theme-dark);
  color: var(--cf-theme-white);
  text-shadow: none;
}

*::-moz-placeholder {
  color: var(--cf-common-black);
  font-size: var(--cf-fs-body);
  opacity: 1;
}

*::-webkit-input-placeholder {
  color: var(--cf-common-black);
  font-size: var(--cf-fs-body);
  opacity: 1;
}

*:-ms-input-placeholder {
  color: var(--cf-common-black);
  font-size: var(--cf-fs-body);
  opacity: 1;
}

*::-ms-input-placeholder {
  color: var(--cf-common-black);
  font-size: var(--cf-fs-body);
  opacity: 1;
}

*::placeholder {
  color: var(--cf-common-black);
  font-size: var(--cf-fs-body);
  opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/
img {
  max-width: 100%;
  height: auto;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.w-100 {
  width: 100%;
}

.social-icons a {
  margin-right: 10px;
}

.c-pointer {
  cursor: pointer;
}

.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
}

.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.content-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

.content-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content-space-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.position-absolute {
  position: fixed !important;
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.position-sticky {
  position: sticky;
}

.position-unset {
  position: unset;
}

.text-primary {
  color: #676cdb !important;
}

.bg-primary {
  background-color: #676cdb;
}

.text-secondary {
  color: #ED6D1D;
}

.bg-secondary {
  background-color: #ED6D1D;
}

.text-purple {
  color: #150b2f;
}



.text-white {
  color: #ffffff;
}

.bg-white {
  background-color: #ed6d1d00;
}

.text-dark {
  color: #212025;
}

.bg-dark {
  background-color: #212025;
}

.text-hero-bg {
  color: #212025;
}

.bg-hero-bg {
  background-color: #212025;
}

.text-dark {
  color: #212121;
}

.bg-dark {
  background-color: #212121;
}

.text-light {
  color: #a6a6a6;
}

.bg-light {
  background-color: #4f4f4f;
}

.text-body {
  color: #9f9f9f;
}

.bg-body {
  background-color: #9f9f9f;
}

.text-1 {
  color: #000;
}

.bg-1 {
  background-color: #000;
}

.text-1 {
  color: #f7f7f7;
}

.bg-1 {
  background-color: #f7f7f7;
}

.font-18px {
  font-size: 18px;
}

.font-18px {
  font-size: 18px;
}

.font-72px {
  font-size: 72px;
}

.font-48px {
  font-size: 48px;
}

.font-24px {
  font-size: 24px;
}

.font-22px {
  font-size: 22px;
}

.font-18px {
  font-size: 18px;
}

.font-14px {
  font-size: 14px;
}

/*----------------------------------------*/
/*  02. BS Grid Css Start
/*----------------------------------------*/
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 576px) {
  .container {
    width: 540px;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1360px;
    max-width: 100%;
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 576px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 768px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 992px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 1200px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 768px) {

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 992px) {

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* 00. ========= sticky animation ========== */
@-webkit-keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

/*----------------------------------------*/
/*  03. Button Css Start
/*----------------------------------------*/
.cf-btn {
  background: var(--cf-theme-primary);
  border: 1px solid var(--cf-theme-primary);
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--cf-theme-white);
  padding: 10px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--cf-theme-white);
  display: inline-block;
}

.cf-btn:hover {
  color: var(--cf-theme-white);
  -webkit-transform: translateY(-0.15rem);
  transform: translateY(-0.15rem);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(30, 34, 40, 0.15);
  box-shadow: 0 0.25rem 0.75rem rgba(30, 34, 40, 0.15);
}

/*----------------------------------------*/
/*  04. Modal Css Start
/*----------------------------------------*/
.axo-popup .mfp-content {
  background: var(--cf-theme-white);
  width: 500px;
  margin: 0 auto;
  border-radius: 20px;
}

@media (max-width: 575px) {
  .axo-popup .mfp-content {
    width: 300px;
  }
}

.axo-popup .mfp-content .mfp-close {
  background: var(--cf-theme-white);
  color: var(--cf-theme-dark);
  border-radius: 50%;
  margin: 10px;
}

.axo-popup .mfp-content .content .price {
  color: var(--cf-theme-dark);
  font-size: 20px;
  margin-top: 0 !important;
}

.axo-popup .mfp-content .content .img {
  margin-top: -2px;
}

.axo-popup .mfp-content .content .img img {
  width: 100%;
  border-radius: 20px 20px 0 0;
}

.axo-popup .mfp-content .content .des {
  padding: 20px 30px 20px;
}

.axo-popup .mfp-content .content .des span {
  color: var(--cf-theme-primary);
  font-size: 18px;
}

.axo-popup .mfp-content .content .des h4 {
  color: var(--cf-theme-dark);
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 1.3;
}

@media (max-width: 575px) {
  .axo-popup .mfp-content .content .des h4 {
    font-size: 18px;
  }
}

.axo-popup .mfp-content .content .des .category {
  margin: 0;
  margin-bottom: 5px;
}

.axo-popup .mfp-content .content .des p {
  margin-top: 20px;
}

.axo-popup .mfp-content .content .des .d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 575px) {
  .axo-popup .mfp-content .content .des .d-flex {
    display: block;
  }
}

.axo-popup .mfp-content .content .des ul li {
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
}

@media (max-width: 575px) {
  .axo-popup .mfp-content .content .des ul li {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.axo-popup .mfp-content .content .des ul li a {
  color: var(--cf-theme-primary);
  font-size: 14px;
}

.axo-popup .mfp-content .content .des ul li::before {
  content: "";
  height: 7px;
  width: 7px;
  display: block;
  border-radius: 50%;
  margin-right: 5px;
}

/*----------------------------------------*/
/*  05. Section Tilte CSS START
/*----------------------------------------*/
.section-title {
  text-align: center;
  color: var(--cf-theme-dark);
}

.section-title p {
  color: var(--cf-theme-primary);
  margin-bottom: 5px;
  font-size: var(--cf-fs-h4);
}

.section-title h2 {
  font-weight: var(--cf-fw-bold);
}

.section-title.left {
  text-align: left;
}

/*----------------------------------------*/
/*  06. Preloader CSS START
/*----------------------------------------*/
#preloader:after {
  left: auto;
  right: 0;
}

#preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  background-color: var(--cf-theme-dark);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

#preloader:after {
  left: auto;
  right: 0;
}

#preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}

#preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}

.loader_line:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 0%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: white;
  -webkit-animation: lineheight 1000ms ease-in-out 0s forwards;
  animation: lineheight 1000ms ease-in-out 0s forwards;
}

.loader_line:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: var(--cf-theme-dark);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-animation: lineround 1200ms linear 0s infinite;
  animation: lineround 1200ms linear 0s infinite;
  -webkit-animation-delay: 2000ms;
  animation-delay: 2000ms;
}

@-webkit-keyframes lineheight {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

@keyframes lineheight {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

@-webkit-keyframes lineround {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
}

@keyframes lineround {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
}

/*hiding all*/
.preloaded .loader_line:after {
  opacity: 0;
}

.preloaded .loader_line {
  opacity: 0;
  height: 100% !important;
}

.preloaded:before,
.preloaded:after {
  -webkit-animation: preloadedzero 300ms ease-in-out 500ms forwards;
  animation: preloadedzero 300ms ease-in-out 500ms forwards;
}

@-webkit-keyframes preloadedzero {
  0% {
    width: 50%;
  }

  100% {
    width: 0%;
  }
}

@keyframes preloadedzero {
  0% {
    width: 50%;
  }

  100% {
    width: 0%;
  }
}

body.intro #preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}

body.intro .loader_line:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 0%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: var(--cf-theme-dark);
  -webkit-animation: lineheight 1000ms ease-in-out 0s forwards;
  animation: lineheight 1000ms ease-in-out 0s forwards;
}

body.intro .loader_line:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: var(--cf-theme-dark);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-animation: lineround 1200ms linear 0s infinite;
  animation: lineround 1200ms linear 0s infinite;
  -webkit-animation-delay: 2000ms;
  animation-delay: 2000ms;
}

body.intro #preloader:before,
body.intro #preloader:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  background-color: var(--cf-theme-dark);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

body.intro #preloader:after {
  left: auto;
  right: 0;
}

body.intro #preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}

/*----------------------------------------*/
/*  07. pogress CSS START
/*----------------------------------------*/
.progress {
  background: transparent;
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.progress::after {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  content: "\f176";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  color: var(--cf-theme-dark);
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress:hover::after {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  content: "\f176";
  color: var(--cf-theme-dark);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress svg path {
  fill: none;
}

.progress svg.progress-svg path {
  stroke: var(--cf-theme-dark);
  stroke-width: 4;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

/*----------------------------------------*/
/*  08. cursor CSS START
/*----------------------------------------*/
.cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
  margin-left: -12.5px;
  margin-top: -12.5px;
  width: 25px;
  height: 25px;
  opacity: 0.6;
  z-index: 999;
  background-color: var(--cf-theme-primary);
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cursor {
    display: none;
  }
}

.cursor.cursor-hover {
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  background-color: var(--cf-theme-primary);
  opacity: 0.3;
}

/*----------------------------------------*/
/*  09. header CSS START
/*----------------------------------------*/
header {
  padding: 30px 0;
  z-index: 9;
  /* background-color: #ed6d1d8c; */
}

header .logo-bg {
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 100%;
  background-color: #ffffff7c;
}

header .logo-bg p {
  position: absolute;
  margin-top: 27px;
  margin-left: 280px;

}

header .logo {
  width: 80px;
  height: 80px;
}

header .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}


header.fixed .container .menus li {
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  color: var(--cf-theme-dark);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  header .container {
    display: block !important;
    position: relative;

  }

  header .logo-bg {
    display: flex;
    justify-content: left;
    text-align: left;
    border-radius: 100%;
    background-color: #ffffff7c;
    height: 50px;
    width: 50px;
  }

  header .logo-bg p {
    position: absolute;
    margin-top: 8px;
    margin-left: 60px;
    font-size: 0.9em;
  }

  header .logo {
    width: 50px;
    height: 50px;
  }

  header .container .menus li {
    -webkit-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
    color: #000 !important;
    font-size: 0.8em;
    text-align: center;
  }

  .toggle.toggle-static span{
    background: #000 !important;
  }

  header.sliding .container .toggle span {
    background: #000 !important;
  }

  header.active .container .toggle span {
    background: #000 !important;
  }


}

header .container .menus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 40px;
  color: #000;
  z-index: 1000000;
}

header .container .menus li {
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  color: #ffffff;
}

header .container .menus li.current {
  color: var(--cf-theme-primary);
  position: relative;
}

header .container .menus li.current::after {
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  /* content: ""; */
  background: var(--cf-theme-white);
  height: 6px;
  width: 6px;
  border-radius: 10px;
  margin-left: 5px;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  header .container .menus {
    display: none;
    margin-top: 20px;
  }

  header .container .menus li {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  header .container .social-icons {
    display: none;
  }
}


header .container .toggle {
  position: relative;
  width: 40px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  display: none;
  position: absolute;
  right: 10%;
  top: 0;
}

header .container .toggle span {
  position: absolute;
  width: 40px;
  height: 3px;
  background: var(--cf-theme-white);
  border-radius: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  right: 0;
}

header .container .toggle span:nth-child(1) {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  width: 25px;
}

header .container .toggle span:nth-child(2) {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  width: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  header .container .toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

header.active {
  max-height: 100%;
  color: var(--cf-theme-dark);
  background: var(--cf-theme-white) !important;
  box-shadow: -1px 9px 5px 10px rgba(159, 159, 159, 0.27);
  padding-bottom: 30px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

header .active p ul li a {
  color: var(--cf-theme-white);
}

header.active .toggle span:nth-child(1) {
  width: 30px;
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
  -webkit-transition-delay: 0.125s;
  transition-delay: 0.125s;
}

header.active .toggle span:nth-child(2) {
  width: 30px;
  -webkit-transform: translateY(0) rotate(315deg);
  transform: translateY(0) rotate(315deg);
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

header.active .toggle span:nth-child(3) {
  -webkit-transform: translateX(60px);
  transform: translateX(60px);
}

header.fixed {
  position: fixed !important;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  -webkit-box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  z-index: 1000;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
  -webkit-animation: sticky 1s;
  animation: sticky 1s;
  color: var(--cf-theme-dark);
  background: var(--cf-theme-white);
}

/*----------------------------------------*/
/*  10. hero CSS START
/*----------------------------------------*/
#home {
  position: relative;
  background-image: url(../images/home-bg.png);
  background-color: #040D12;
  background-position: right;
  background-repeat: no-repeat;
}

#home .col-lg-7.col-12 {
  z-index: 2;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

#home h2 {
  padding-top: 140px;
  padding-bottom: 30px;
  font-size: 58px;
}

#home .short-bio {
  margin-top: 10px;
  margin-bottom: 30px;
}

#home .counter {
  margin-top: 80px;
  width: 100%;
  align-items: center;
}

#home .imghero {

  display: block;
  margin: 0 auto;
}

#home .counter h2 {
  font-size: var(--cf-fs-h2);
}

#home .home-circle img {
  width: auto;
}

#home span.ring1 {
  position: absolute;
  bottom: 10%;
  left: 50px;
}

#home span.ring1 img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #838485;
}

#home span.ring2 {
  position: absolute;
  top: 65%;
  left: 30%;
}

#home span.ring2 img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid #656fe0;
}

#home span.ring3 {
  position: absolute;
  right: 15px;
  bottom: 12%;
}

#home span.ring3 img {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  border: 2px solid #838485;
}

#home span.ring4 {
  position: absolute;
  bottom: 10%;
  left: 46%;
}

#home span.ring4 img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 2px solid #656fe0;
}

#home span.ring5 {
  position: absolute;
  top: 60%;
  right: 30%;
}

#home span.ring5 img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #656fe0;
}

#home .animate-v {
  animation: upNdown1 2s infinite linear;
}

#home .animate-curve:after {
  animation: upNdown2 2s infinite linear;
  animation: upNdown2 2s infinite linear;
}

#home .animate-v2 {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 2s infinite alternate;
}

#home .animate-v3 {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

#home .hero-btn {
  justify-content: center;
}

#home .hero-btn .cf-btn {
  padding: 8px !important;
}

#home .hero-btn p {
  margin-top: 15px;
  margin-left: 10px;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  #home .counter h2 {
    font-size: 18px;
  }

  #home .title h2 {
    font-size: 24px;
  }

  #home .extra-bg {
    display: none;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 70px;
    z-index: 0;
  }
}

#home .extra-bg {
  display: none;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 70px;
  z-index: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  #home .extra-bg {
    display: block;
  }

  #home .hero-btn {
    justify-content: center;
  }

  #home .hero-btn .cf-btn {
    padding: 8px !important;
    font-size: 0.8em !important;
  }

  #home .hero-btn .sub-label {
    font-size: 0.9em !important;
  }

  #home .hero-btn .sub-label a {
    display: contents !important;
  }

}

#home .hero-center {
  top: 20%;
  position: absolute;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  #home .hero-center {
    display: none;
  }
}

#home .hero-center-bg {
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 480px) {
  #home .hero-center-bg {
    width: 120px;
  }

  #home span.ring1 {
    position: absolute;
    bottom: 10%;
    left: 20px;
  }

  #home span.ring1 img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #838485;
  }

  #home span.ring2 {
    position: absolute;
    top: 75%;
    left: 30%;
  }

  #home span.ring2 img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #656fe0;
  }

  #home span.ring3 {
    position: absolute;
    right: 15px;
    bottom: 8%;
  }

  #home span.ring3 img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid #838485;
  }

  #home span.ring4 {
    position: absolute;
    bottom: 10px;
    left: 46%;
  }

  #home span.ring4 img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 2px solid #656fe0;
  }

  #home span.ring5 {
    position: absolute;
    top: 80%;
    right: 30%;
  }

  #home span.ring5 img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #656fe0;
  }

  #home .hero-center img {
    position: absolute;
    bottom: 0;
    right: -60px;
    max-width: 500px;
  }
}




@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  #home .hero-center img {
    display: none;
  }
}

/*----------------------------------------*/
/*  11. service CSS START
/*----------------------------------------*/
#services {
  margin-top: 120px;
}

#services .card-container {
  margin: 50px 0 180px;
}

#services .service-card {
  position: relative;
  -webkit-box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.05);
  box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.05);
  border-radius: 20px;
  padding: 40px;
  width: 25rem;
  height: 25rem;
  margin-bottom: 30px;
  color: var(--cf-theme-dark);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

#services .arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#services .arrow-left {
  margin-right: 10px;
  position: relative;
  z-index: 1;
  bottom: -2px;
}

#services .arrow-right {
  margin-right: 10px;
  position: relative;
  z-index: 1;
  bottom: -2px;
}

#services .arrow {
  width: 60px;
  height: 60px;
  overflow: hidden;
  background-color: #f2f2f2;
  border-radius: 50px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

#services .arrow.active {
  background: var(--cf-theme-primary);
  color: var(--cf-theme-white);
}

#services .arrow:hover {
  background: var(--cf-theme-primary);
  color: var(--cf-theme-white);
}

#services .icon-container {
  display: flex;
  flex-direction: row;
  margin: 8px;
}

#services .icon-container .icon {
  width: 80px;
  margin-right: 8px;
}

#services .service-card .icon-container svg {
  color: var(--cf-theme-dark);
}

#services .service-card h3 {
  margin: 25px 0 17px;
}

#services .service-card:hover {
  background: var(--cf-theme-primary);
  color: var(--cf-theme-white);
}

#services .service-card:hover p {
  color: var(--cf-theme-white);
}

#services .service-card:hover svg {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

#services .service-card a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

/*----------------------------------------*/
/*  12. about CSS START
/*----------------------------------------*/
#about {
  margin-bottom: 200px;
}

#about .section-title {
  margin-bottom: 25px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  #about .section-title {
    margin-top: 100px;
  }
}

#about .cf-btn {
  padding: 15px 20px;
}

#about .btn-container {
  margin-top: 50px;
}

#about .author-image-container {
  position: relative;
  height: 100%;
}


#about .author-image-container .image-bg {
  position: absolute;
  width: 550px;
  height: 350px;
  background-color: var(--cf-theme-primary);
  bottom: -65px;
  left: -32px;
  z-index: 0;
  border-radius: 20px;

}


/* CSS for mobile phones */
@media (max-width: 480px) {
  #about .author-image-container .image-bg {
    position: absolute;
    width: 310px;
    height: 200px;
    background-color: var(--cf-theme-primary);
    border-radius: 20px;
    padding: 0;
    left: 20px;
    z-index: -1;
  }
  #about .author-image-container .image-area {
    width: 300px;
    height: auto;
    margin-left: 50px;
    
  }
  #about .section-title {
    margin-bottom: 25px;
    text-align: center;
  }
}

/* #about .container .about-column {
  padding: 0 !important;
  margin: 0 !important;
} */

@media only screen and (min-width: 576px) and (max-width: 1024px) {
  #about .author-image-container .image-bg {
    position: absolute;
    width: 100%;
    height: 230px;
    background-color: var(--cf-theme-primary);
    bottom: -100px;
    left: 0;
    z-index: -1;
    border-radius: 20px;
    padding: 0;
  }

  /* #about .container .about-column {
    padding: 0 !important;
    margin: 0 !important;
  } */
}



@media only screen and (min-width: 768px) and (max-width: 991px) {
  #about .author-image-container .image-bg {
    width: 300px;
    height: 200px;
    left: -47px;
    border-radius: 10px;
  }
}

#about .author-image-container .image-area img {
  border-radius: 20px;
  position: relative;
  top: 0;
}

/*----------------------------------------*/
/*  13. portfolio CSS START
/*----------------------------------------*/
#portfolio {
  margin-bottom: 120px;
}

#portfolio .portfolio-container .portfolio-items {
  width: 100%;
  margin: 4px;
}

#portfolio .portfolio-container .filter-btn {
  text-align: center;
  margin: 24px 0 40px;
  color: var(--cf-theme-dark);
}

#portfolio .portfolio-container .filter-btn a {
  margin: 15px 40px 0 0;
  display: inline-block;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  #portfolio .portfolio-container .filter-btn a {
    margin: 15px 20px 0 0;
  }
}

#portfolio .portfolio-container .filter-btn a.active {
  color: var(--cf-theme-primary);
}

#portfolio .portfolio-container .filter-btn a.active::after {
  content: "";
  background: var(--cf-theme-primary);
  height: 6px;
  width: 6px;
  border-radius: 10px;
  margin-left: 5px;
  display: inline-block;
}

/* #portfolio .portfolio-container .item {
  overflow: hidden;
  margin: 10px;
  border-radius: 20px;
  width: calc(33% - 20px);
  background-color: red;
} */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #portfolio .portfolio-container .item {
    width: calc(50% - 20px);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  #portfolio .portfolio-container .item {
    width: calc(100% - 20px);
  }
}

#portfolio .portfolio-container .item a img {
  width: 100%;
  border-radius: 20px;
  -webkit-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  vertical-align: middle;
}

#portfolio .portfolio-container .item a img:hover {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

/* card all product */
#portfolio .portfolio-container .portfolio-items .item {
  display: flex;
  flex-direction: row;
  flex-grow: 3;
  flex-wrap: wrap;
  gap: 30px;
  align-content: space-around;
  align-items: center;
}

#portfolio .item {
  margin-left: 110px;
}

#portfolio .card {
  margin-top: 80px;
}

#portfolio .portfolio-container .item .card {
  height: 300px;
  width: 250px;
  overflow: hidden;
  position: relative;
  border-radius: 1rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all .3s ease;
    z-index: -1;
  }

  &.card1 {
    &::before {
      background-image: url('../../assets/images/portfolio/4.jpg');
    }
  }

  &.card2 {
    &::before {
      background-image: url('../../assets/images/portfolio/1.jpg');
    }
  }

  &.card3 {
    &::before {
      background-image: url('../../assets/images/portfolio/2.jpg');
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: var(--cf-theme-white);
    transition: #f5f5f5 .3s ease;
    opacity: 0;
  }

  .title {
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  .subtitle {
    text-align: center;
    color: var(--cf-theme-white);
  }

  &:hover {
    &:before {
      transform: scale(1.2);
    }

    .card-content {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(8px);
    }
  }
}

/*----------------------------------------*/
/*  14. testimonials CSS START
/*----------------------------------------*/
#testimonials .testimonials-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#testimonials .container {
  margin-bottom: 50px;
}

#testimonials .testimonials-top .arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#testimonials .testimonials-top .arrow-left {
  margin-right: 10px;
}

#testimonials .testimonials-top .arrow {
  width: 60px;
  height: 60px;
  overflow: hidden;
  background-color: #f2f2f2;
  border-radius: 50px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

#testimonials .testimonials-top .arrow.active {
  background: var(--cf-theme-primary);
  color: var(--cf-theme-white);
}

#testimonials .testimonials-top .arrow:hover {
  background: var(--cf-theme-primary);
  color: var(--cf-theme-white);
}

#testimonials .testimonial-sliders {
  margin-top: 50px;
  -webkit-box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.05);
  box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.05);
  border-radius: 20px;
}

#testimonials .slider-item {
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.07);
  box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.07);
}

#testimonials .slider-item .author-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  margin-top: 40px;
}

#testimonials .slider-item .author-info img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

#testimonials .slider-item h3 {
  color: var(--cf-theme-dark);
}

#testimonials .slider-item .complay-name {
  font-size: 20px;
}

#testimonials .slider-item p {
  font-style: italic;
}

#testimonials .slider-item .slider-item-content {
  padding: 48px 36px 30px;
}

/*----------------------------------------*/
/*  15. client CSS START
/*----------------------------------------*/
#client {
  margin: 120px 0;
}

#client .client-container {
  border-radius: 20px;
  padding-top: 10px;
}

#client .client-container a {
  height: 100%;
}

#client .client-container img,
#client .client-container a {
  -webkit-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  max-width: 100%;
}

#client .client-container .swiper-slide-active img,
#client .client-container .swiper-slide:hover img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

/*----------------------------------------*/
/*  16. blog CSS START
/*----------------------------------------*/
#blog {
  margin-bottom: 180px;
}

#blog .blog-items {
  margin-top: 50px;
}

#blog .blog-item {
  border-radius: 20px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.05);
  box-shadow: 0px 20px 35px 10px rgba(4, 9, 30, 0.05);
}

#blog .blog-item span {
  color: var(--cf-theme-primary);
}

#blog .blog-item .blog-image-container {
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}

#blog .blog-item img {
  width: 100%;
  border-radius: 20px 20px 0 0;
  -webkit-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  vertical-align: middle;
}

#blog .blog-item img:hover {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

#blog .blog-item .blog-content {
  padding: 30px;
}

#blog .blog-item .blog-content p {
  margin-bottom: 0;
}

#blog .blog-item .blog-content h4 {
  margin-top: 0;
}

#blog .blog-item h4 {
  color: var(--cf-theme-dark);
  margin: 10px 0;
}

/*----------------------------------------*/
/*  17. contact CSS START
/*----------------------------------------*/
#contact {
  margin-bottom: 120px;
  margin-top: 80px;
}

#contact .info-details {
  margin-top: 36px;
}
#contact .info-details p {
  
  font-size: 20px;
}

#contact .info-details li {
  margin-bottom: 30px;
}

#contact .info-details li h4 {
  color: var(--cf-theme-dark);
}

#contact .contact-form {
  background: var(--cf-theme-primary);
  padding: 100px 50px 50px;
  border-radius: 20px;
}

#contact .contact-form input,
#contact .contact-form textarea {
  width: 100%;
  color: var(--cf-theme-white);
  font-weight: var(--cf-fw-light);
  font-family: var(--cf-ff-primary);
  font-size: 18px;
  border: 0;
  border-bottom: 1.5px solid #a6a6a6;
  background: transparent;
  padding: 15px 0;
  margin-bottom: 40px;
}

#contact .contact-form button {
  background: var(--cf-theme-dark);
  color: var(--cf-theme-white);
  font-weight: var(--cf-fw-regular);
  font-family: var(--cf-ff-primary);
  font-size: 18px;
  margin: 0 auto;
  display: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sub-btn {
  background: var(--cf-theme-secondary);
  border: 1px solid var(--cf-theme-secondary);
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--cf-theme-white);
  padding: 10px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--cf-theme-white);
  display: inline-block;
}

.sub-btn:hover {
  color: var(--cf-theme-white);
  -webkit-transform: translateY(-0.15rem);
  transform: translateY(-0.15rem);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(30, 34, 40, 0.15);
  box-shadow: 0 0.25rem 0.75rem rgba(30, 34, 40, 0.15);
}
@media (max-width: 480px) {
  #contact .info-details p {
    font-size: 17px;
  }
  #contact .section-title h3 {
    font-size: 30px;
  }
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*----------------------------------------*/
/*  18. footer CSS START
/*----------------------------------------*/
footer {
  background: var(--cf-theme-primary);
  padding: 37px 0;
}

footer p {
  color: white;
}

footer .logo-out {
  margin-left: 8px;
}

footer .logo-out p {
  margin-left: 20px;
  margin-top: 40px;
}

footer .logo-adr {
  text-align: start;
  margin-top: 30px;
  margin-left: 20px;
}

footer .logo {
  width: 100px;
  height: 100px;
  background-color: var(--cf-theme-white);
  border-radius: 100%;
}

footer .copy-right-text {
  margin-top: 10px;
  font-weight: 400;
}

footer .title {
  color: var(--cf-theme-secondary);
}

footer .footer-widget .title {
  line-height: 30px;
  color: var(--cf-theme-secondary);
}

footer .footer-widget {
  margin-left: 115px;
  line-height: 40px;
}

footer .footer-widget .links-widget ul .link-list li+li {
  margin-top: 4px;
}

footer .footer-widget .links-widget ul .link-list a {
  display: block;
  position: relative;
  padding-left: 15px;
  transition: all .4s ease;
}

footer .footer-widget .links-widget ul .link-list a:before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--cf-theme-white);
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all .4s ease;
}

footer .footer-widget.links-widget ul.link-list a:hover {
  color: var(--cf-theme-secondary);
}

footer .footer-widget.links-widget ul.link-list a:hover:before {
  background: var(--cf-theme-secondary);
}

footer .footer-widget.explore {
  padding-left: 40px;
}



/* CSS for mobile phones */
@media (max-width: 480px) {
  .logo-adr {
    text-align: left;
  }

  .logo-adr p {
    font-size: 0.8em;
  }

  .logo-adr .copyright {
    display: none;
  }

  .footer-info-column .title {
    position: relative;
    left: 16px;
    text-align: left;
  }

  .footer-info-column .site-links {
    margin-left: 0 !important;
    margin-right: 0 !important;
    position: relative;
    left: 16px;
    text-align: left;
    margin-bottom: 1.5rem;
    font-size: 0.8em;
  }

  .footer-info-column .email {
    text-align: left;
    position: relative;
    left: 16px;
    font-size: 0.8em;
  }

  .footer-info-column .social-icons {
    text-align: left;
    position: relative;
    left: 16px;
    font-size: 0.8em;
  }

}


@media (min-width: 768px) and (max-width: 1024px) {
  .logo-adr {
    text-align: left;
  }

  .logo-adr p {
    font-size: 0.8em;
  }

  .logo-adr .copyright {
    display: none;
  }

  .footer-info-column .title {
    position: relative;
    left: 16px;
    text-align: left;
  }

  .footer-info-column .site-links {
    margin-left: 0 !important;
    margin-right: 0 !important;
    position: relative;
    left: 16px;
    text-align: left;
    margin-bottom: 1.5rem;
    font-size: 0.8em;
  }

  .footer-info-column .email {
    text-align: left;
    position: relative;
    left: 16px;
    font-size: 0.8em;
  }

  .footer-info-column .social-icons {
    text-align: left;
    position: relative;
    left: 16px;
    font-size: 0.8em;
  }
}