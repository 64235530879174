.ant-modal-root .ant-modal-mask {
  background-color: rgb(0 0 0 / 71%) !important;
  pointer-events: none;
}

.back-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  opacity: 0;
  z-index: 10000 !important;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.back-to-top-button.visible {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 480px) {
  .page-title h3 {
    font-size: 40px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .swiper-row {
    overflow-x: auto;
    gap: 20px;
    justify-content: space-between !important;
  }

  .swiper-row .btn-swipper {
    font-size: 0.8em;
    margin-right: 0 !important;
  }

  .swiper-slide-row {
    justify-content: unset !important;
  }

  .swiper-slide-row p {
    font-size: 0.8em;
  }

  .swipper-nav {
    display: none !important;
  }

  .img-aset {
    width: 100% !important;
  }


}