/************************** 
* imported styles 
***************************/
@import url(https://fonts.googleapis.com/css?family=Pacifico|Rubik:300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(../plugins/printify-icon/style.css);
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  /** @font family declaration */
  --cf-ff-body: 'Rubik', sans-serif;
  --cf-ff-heading: 'Poppins', sans-serif;
  --cf-ff-subheading: 'Poppins', sans-serif;
  --cf-ff-p: 'Rubik', sans-serif;
  --cf-ff-fontawesome: "Font Awesome 6 Pro";
  /** @color declaration */
  --cf-theme-primary: #4261ab;
  --cf-theme-secondary: #ED6D1D;
  --cf-theme-purple: #150b2f;
  --cf-theme-white: #ffffff;
  --cf-theme-dark: #212025;
  --cf-theme-hero-bg: #212025;
  --cf-grey-dark: #212121;
  --cf-grey-light: #a6a6a6;
  --cf-text-body: #9f9f9f;
  --cf-text-1: #000;
  --cf-border-1: #f7f7f7;
  /** @font weight declaration */
  --cf-fw-normal: normal;
  --cf-fw-elight: 200;
  --cf-fw-light: 300;
  --cf-fw-regular: 400;
  --cf-fw-medium: 500;
  --cf-fw-sbold: 600;
  --cf-fw-bold: 700;
  --cf-fw-ebold: 800;
  --cf-fw-black: 900;
  /** @font size declaration */
  --cf-fs-body: 18px;
  --cf-fs-p: 18px;
  --cf-fs-h1: 72px;
  --cf-fs-h2: 48px;
  --cf-fs-h3: 24px;
  --cf-fs-h4: 22px;
  --cf-fs-h5: 18px;
  --cf-fs-h6: 14px;
}

/* body {
    font-family: 'Rubik';
    color: #757575;
    font-size: 18px;
    line-height: 32px; } */

a:hover,
a:visited,
a:focus,
a:active {
  text-decoration: none !important;
  outline: none !important;
}

.thm-container {
  width: 100%;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.sec-pad {
  padding: 120px 0;
}

.p0 {
  padding: 0 !important;
}

.pt0 {
  padding-top: 0;
}

.pb0 {
  padding-bottom: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.gray-bg {
  background: #F4F9FD;
}

.thm-btn {
  border: none;
  outline: none;
  display: inline-block;
  vertical-align: middle;
  background: #ED6D1D;
  color: #fff;
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 16px;
  padding: 18px 69px;
  transition: all .4s;
}

.thm-btn:hover {
  background: #fff;
  color: #ED6D1D;
}

.thm-btn.yellow-bg {
  background: #FEC931;
  color: #212121;
}

.thm-btn.yellow-bg:hover {
  background: #ED6D1D;
  color: #fff;
}

.sec-title {
  margin-top: -8px;
  margin-bottom: 55px;
}

.sec-title span {
  color: var(--cf-theme-primary);
  margin-bottom: 5px;
  font-size: var(--cf-fs-h4);
}

.sec-title h3 {
  margin: 0;
  color: #212121;
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 30px;
}

.sec-title p {
  color: #757575;
  font-size: 20px;
  line-height: 32px;
  margin: 0;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  width: 50px;
  height: 50px;
  text-align: center;
  background: #ED6D1D;
  color: #fff;
  font-size: 17px;
  line-height: 50px;
  cursor: pointer;
  display: none;
  box-shadow: 0px 23px 42px rgba(0, 0, 0, 0.2);
  transition: all .4s ease;
}

.scroll-to-top:hover {
  background: #FEC931;
  color: #212121;
}

/************************** 
* 1. preloader styles 
***************************/
body.active-preloader-ovh {
  overflow: hidden;
}

.preloader {
  background-color: #ffffff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999;
}

.preloader .spinner {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  background-color: #ED6D1D;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

/************************** 
* 2. page title styles 
***************************/
.page-title {
  background: #F5F6FA;
  padding: 79px 0;
}

.page-title h3 {
  margin: 0;
  color: #212121;
  font-size: 60px;
  font-weight: bold;
}

.breadcumb-wrapper {
  overflow: hidden;
  margin-top: -34px;
  z-index: 99;
}

.breadcumb-wrapper .thm-container {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  max-width: 1170px;
}

.breadcumb-wrapper ul.breadcumb,
.breadcumb-wrapper ul.breadcumb li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.breadcumb-wrapper ul.breadcumb {
  background: #ED6D1D;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  padding-left: 70px;
  padding-right: 100px;
  position: relative;
  display: inline-block;
  float: right;
}

.breadcumb-wrapper ul.breadcumb:after {
  content: '';
  width: 1000000px;
  height: 100%;
  background: #ED6D1D;
  position: absolute;
  top: 0;
  left: 100%;
}

.breadcumb-wrapper ul.breadcumb li {
  display: inline-block;
  vertical-align: middle;
}

.breadcumb-wrapper ul.breadcumb li a,
.breadcumb-wrapper ul.breadcumb li span {
  display: block;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  padding: 18px 0;
  transition: all .4s ease;
}

.breadcumb-wrapper ul.breadcumb li a:hover,
.breadcumb-wrapper ul.breadcumb li span {
  color: #212121;
}

.breadcumb-wrapper ul.breadcumb li span.sep {
  margin-left: 15px;
  margin-right: 15px;
  color: #fff;
}

/************************** 
* 3. video box styles 
***************************/
.video-box {
  position: relative;
}

.video-box .content {
  width: 325px;
  background: #fff;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 55px 0;
  padding-left: 60px;
}

.video-box .content h3 {
  margin: 0;
  color: #212121;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
}

.video-box a.video-btn {
  width: 74px;
  height: 74px;
  background: #ED6D1D;
  color: #fff;
  line-height: 74px;
  font-size: 20px;
  position: absolute;
  text-align: center;
  bottom: 40px;
  right: 40px;
}


/************************** 
* 5. services styles 
***************************/
.service-style-one {
  background: #F5F6FA;
  position: relative;
}

.service-style-one img.background-right {
  position: absolute;
  top: 0;
  right: 0;
}

.service-style-one .service-content {
  padding-right: 60px;
}

.service-style-one .service-content .title {
  margin-bottom: 40px;
}

.service-style-one .service-content .title span,
.service-style-one .service-content .title h2 {
  margin: 0;
}

.service-style-one .service-content .title span {
  font-family: 'Pacifico';
  font-size: 20px;
  color: #ED6D1D;
}

.service-style-one .service-content .title h2 {
  color: #212121;
  font-size: 60px;
  line-height: 60px;
  font-weight: bold;
}

.service-style-one .service-content p {
  margin: 0;
  color: #757575;
}

.service-style-one .service-content p+p {
  margin-top: 35px;
}

.service-style-one .service-content .btn-box {
  margin-top: 50px;
}

.service-style-one .service-content .btn-box a.thm-btn {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}

.service-style-one .service-content .btn-box .phone-btn {
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  font-weight: 500;
  color: #212121;
}

.service-style-one .service-content .btn-box .phone-btn i {
  font-size: 24px;
  border: 2px solid #2884C6;
  color: #2884C6;
  line-height: 55px;
  width: 59px;
  height: 59px;
  text-align: center;
  margin-right: 10px;
}

.service-style-one .service-right-content {
  background: #fff;
  padding: 60px;
}

.service-style-one .service-right-content .inner {
  position: relative;
}

.service-style-one .service-right-content .divider {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 100%;
  background: #ebedf4;
}

.service-style-one .service-right-content .divider.ver {
  width: 100%;
  height: 2px;
}

.service-style-one .service-right-content .single-service-one {
  text-align: center;
  padding: 50px 0;
  margin-left: -15px;
  margin-right: -15px;
  display: block;
}

.service-style-one .service-right-content .single-service-one i {
  font-size: 58px;
  color: #2884C6;
  transition: all .4s;
}

.service-style-one .service-right-content .single-service-one h3 {
  margin: 0;
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  transition: all .4s ease;
}

.service-stab-ttyle-one .service-right-content .single-service-one:before {
  background: #ED6D1D;
}

.service-style-one .service-right-content .single-service-one:hover i,
.service-style-one .service-right-content .single-service-one:hover h3 {
  color: #fff;
}

.service-tab-box .tab-title {
  margin-bottom: 60px;
  margin-top: 30px;
  width: 1200px;
}

.service-tab-box .tab-title ul,
.service-tab-box .tab-title li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.service-tab-box .tab-title ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.service-tab-box .tab-title li {
  display: inline-block;
  vertical-align: middle;
  width: 220px;
  padding: 0 1px;
}

.service-tab-box .tab-title li.active a:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.service-tab-box .tab-title li.active a i,
.service-tab-box .tab-title li.active a h3 {
  color: #fff;
}

.service-tab-box .tab-title li a {
  text-align: center;
  padding: 50px 0;
  background: #F5F6FA;
  display: block;
}

.service-tab-box .tab-title li a i {
  font-size: 58px;
  color: #2884C6;
  transition: all .4s;
}

.service-tab-box .tab-title li a h3 {
  margin: 0;
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  transition: all .4s ease;
}

.service-tab-box .tab-title li a:before {
  background: #ED6D1D;
}

.service-tab-box .tab-title li a:hover i,
.service-tab-box .tab-title li a:hover h3,
.service-tab-box .tab-title li a:focus i,
.service-tab-box .tab-title li a:focus h3,
.service-tab-box .tab-title li a:active i,
.service-tab-box .tab-title li a:active h3 {
  color: #fff;
}

.service-style-two {
  position: relative;
  padding-top: 120px;
}

.service-style-two img.background-right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
}

.service-style-two .overlay {
  /* background: #ED6D1D url(../img/cta-bg-1-1.jpg) center center no-repeat fixed; */
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
}

.service-style-two .overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ED6D1D;
  opacity: .9;
}

.service-style-two .thm-container {
  position: relative;
  z-index: 99;
}

.service-style-two .service-content {
  padding-right: 65px;
}

.service-style-two .service-content span,
.service-style-two .service-content h2 {
  margin: 0;
}

.service-style-two .service-content span {
  font-family: 'Pacifico';
  font-size: 20px;
  color: #202020;
}

.service-style-two .service-content h2 {
  color: #FFFFFF;
  font-size: 60px;
  line-height: 60px;
  font-weight: bold;
  margin-bottom: 40px;
}

.service-style-two .service-content p {
  margin: 0;
  color: #FFFFFF;
  line-height: 30px;
}

.service-style-two .unique-feature {
  position: relative;
  display: inline-block;
  margin-top: 35px;
}

.service-style-two .unique-feature .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.service-style-two .unique-feature .content i {
  color: #2884C6;
  font-size: 63px;
  margin-top: 45px;
  margin-bottom: 20px;
  display: block;
}

.service-style-two .unique-feature .content h3 {
  margin: 0;
  color: #202020;
  font-size: 16px;
  font-weight: 500;
  transition: all .4s ease;
}

.service-style-two .unique-feature .content h3:hover {
  color: #ED6D1D;
}

.service-style-two.about-page {
  padding-top: 125.5px;
  padding-bottom: 125.5px;
}

.service-style-three .single-service-three {
  margin-bottom: 50px;
}

.service-style-three .single-service-three:hover .img-box:before {
  opacity: 1;
}

.service-style-three .single-service-three:hover .text-box h3,
.service-style-three .single-service-three:hover .text-box p {
  color: #fff;
}

.service-style-three .single-service-three:hover .text-box .meta-info p {
  color: #fff;
}

.service-style-three .single-service-three:hover .text-box .meta-info p span {
  color: #212121;
}

.service-style-three .single-service-three:hover .text-box a.read-more {
  background: #fff;
  color: var(--cf-theme-secondary);
}

.service-style-three .single-service-three:hover .text-box:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.service-style-three .single-service-three .img-box {
  position: relative;
  overflow: hidden;
}

.service-style-three .single-service-three .img-box>img {
  width: 100%;
}

.service-style-three .single-service-three .img-box:before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(33, 33, 33, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all .4s ease;
}

.service-style-three .single-service-three .text-box {
  position: relative;
  background: #F4F5F9;
  margin-top: 10px;
  padding-top: 55px;
  padding-bottom: 50px;
  padding-left: 60px;
  padding-right: 60px;
}

.service-style-three .single-service-three .text-box:before {
  background: var(--cf-theme-secondary);
}

.service-style-three .single-service-three .text-box h3 {
  margin: 0;
  color: #202020;
  font-weight: bold;
  font-size: 24px;
}

.service-style-three .single-service-three .text-box p {
  color: #747474;
  font-size: 16px;
  line-height: 30px;
  margin: 0;
}

.service-style-three .single-service-three .text-box .meta-info {
  margin-top: 5px;
  margin-bottom: 30px;
}

.service-style-three .single-service-three .text-box .meta-info p {
  margin: 0;
  font-size: 16px;
  color: #747474;
}

.service-style-three .single-service-three .text-box .meta-info p span {
  color: var(--cf-theme-secondary);
  font-weight: bold;
}

.service-style-three .single-service-three .text-box a.read-more {
  width: 42px;
  height: 42px;
  background: var(--cf-theme-secondary);
  color: #fff;
  font-size: 20px;
  line-height: 42px;
  text-align: center;
  position: absolute;
  bottom: -21px;
  right: 30px;
  transition: all .4s ease;
}

.service-style-three .single-service-three .text-box a.read-more:hover {
  background: #fff;
  color: var(--cf-theme-secondary);
}

.service-style-three .pagination {
  margin-bottom: 0;
  margin-top: 30px;
  display: block;
}

.service-style-three .pagination>li+li {
  margin-left: 5px;
}

.service-style-three .pagination>li:last-child {
  margin-left: 0px;
}

.service-style-three .pagination>li>a,
.service-style-three .pagination>li>span {
  padding: 13px 20px;
  float: none;
  display: inline-block;
  color: #212121;
}

.service-style-three .pagination>.active>a,
.service-style-three .pagination>.active>a:focus,
.service-style-three .pagination>.active>a:hover,
.service-style-three .pagination>.active>span,
.service-style-three .pagination>.active>span:focus,
.service-style-three .pagination>.active>span:hover {
  background: var(--cf-theme-secondary);
  color: #fff;
  border-color: var(--cf-theme-secondary);
}

.service-style-three.service-details-page .single-service-three {
  margin-bottom: 0;
}

.service-details-design-option .single-service-details-design-option:hover .img-box:before {
  opacity: 1;
}

.service-details-design-option .single-service-details-design-option .img-box {
  position: relative;
  overflow: hidden;
}

.service-details-design-option .single-service-details-design-option .img-box>img {
  width: 100%;
}

.service-details-design-option .single-service-details-design-option .img-box:before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(33, 33, 33, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all .4s ease;
}

.service-details-design-option .single-service-details-design-option .text-box h3,
.service-details-design-option .single-service-details-design-option .text-box ul,
.service-details-design-option .single-service-details-design-option .text-box li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.service-details-design-option .single-service-details-design-option .text-box h3 {
  color: #202020;
  font-size: 24px;
  font-weight: 500;
  margin-top: 30px;
}

.service-details-design-option .single-service-details-design-option .text-box ul.list-box {
  margin-top: 40px;
}

.service-details-design-option .single-service-details-design-option .text-box ul.list-box li {
  display: block;
  position: relative;
  padding-left: 15px;
  color: #747474;
  font-size: 16px;
  line-height: 30px;
}

.service-details-design-option .single-service-details-design-option .text-box ul.list-box li:before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #747474;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all .4s ease;
}

.service-details-cost-calculator {
  background: #F4F5F9;
}

.service-details-cost-calculator .cost-calc-content {
  padding-right: 30px;
}

.service-details-cost-calculator .cost-calc-content .sec-title {
  margin-bottom: 40px;
}

.service-details-cost-calculator .cost-calc-content .sec-title h3 {
  font-size: 40px;
  margin: 0;
}

.service-details-cost-calculator .cost-calc-content .cost-calc-form {
  margin-bottom: 20px;
}

.service-details-cost-calculator .cost-calc-content .cost-calc-form .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
  outline: none;
}

.service-details-cost-calculator .cost-calc-content .cost-calc-form .bootstrap-select+.bootstrap-select {
  margin-top: 30px;
}

.service-details-cost-calculator .cost-calc-content .cost-calc-form .bootstrap-select>.dropdown-toggle {
  display: inline-block;
  border: none;
  outline: none;
  background: #FFFFFF;
  color: #747474;
  border-radius: 35px;
  font-size: 16px;
  padding: 23px 50px;
  outline: none;
  border: none;
  transition: all .4s ease;
}

.service-details-cost-calculator .cost-calc-content .cost-calc-form .bootstrap-select.btn-group .dropdown-toggle .caret {
  border: none;
  transform: translateY(-50%);
  right: 30px;
  width: auto;
  height: auto;
}

.service-details-cost-calculator .cost-calc-content .cost-calc-form .bootstrap-select.btn-group .dropdown-toggle .caret:before {
  content: '\f107';
  font-family: 'FontAwesome';
  color: #202020;
  font-size: 16px;
}

.service-details-cost-calculator .cost-calc-content .cost-calc-form .dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0px;
}

.service-details-cost-calculator .cost-calc-content .cost-calc-form .dropdown-menu>li>a {
  font-size: 16px;
  padding: 9px 25px;
  transition: all .4s ease;
}

.service-details-cost-calculator .cost-calc-content .cost-calc-form .dropdown-menu>li>a:hover {
  background: #ED6D1D;
  color: #fff;
  cursor: pointer;
}

.service-details-cost-calculator .cost-calc-content p {
  color: #747474;
  font-size: 16px;
  line-height: 1.5em;
  margin: 0;
}

.service-details-cost-calculator .cost-calc-content a {
  color: var(--cf-theme-secondary);
  font-size: 16px;
  line-height: 32px;
  text-decoration: underline !important;
}

.service-details-cost-calculator .pricing-table {
  margin-top: 0px;
  position: relative;
  background: #fff;
}

.service-details-cost-calculator .pricing-table .table {
  margin-bottom: 0;
}

.service-details-cost-calculator .pricing-table .table>thead {
  background: #EAECF3;
  margin-left: -2px;
  margin-right: -2px;
}

.service-details-cost-calculator .pricing-table .table>thead>tr>th {
  color: #202020;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 0;
  padding: 23px 0;
}

.service-details-cost-calculator .pricing-table .table>thead>tr>th:first-child {
  padding-left: 60px;
}

.service-details-cost-calculator .pricing-table .table>tbody>tr:last-child td {
  border-bottom: 2px solid #EBEDF4;
  padding-bottom: 11px;
}

.service-details-cost-calculator .pricing-table .table>tbody>tr>td {
  color: #747474;
  font-size: 16px;
  padding: 0;
  border-top: 0;
}

.service-details-cost-calculator .pricing-table .table>tbody>tr>td .inner {
  padding: 15px 0;
}

.service-details-cost-calculator .pricing-table .table>tbody>tr>td:first-child .inner {
  border-left: 2px solid #EBEDF4;
  padding-left: 60px;
}

.service-details-cost-calculator .pricing-table .table>tbody>tr>td:last-child .inner {
  border-right: 2px solid #EBEDF4;
  padding-right: 60px;
}

.service-details-cost-calculator .pricing-table .table>tbody>tr:first-child td .inner {
  padding-top: 57px;
}

.service-details-cost-calculator .pricing-table .table>tbody>tr:last-child td .inner {
  padding-bottom: 47px;
}

.design-sizes {
  border: 2px solid #EBEDF4;
}

.design-sizes .title {
  background: #EAECF3;
  padding: 26px 0;
}

.design-sizes .title h3 {
  margin: 0;
  color: #202020;
  font-weight: 500;
  font-size: 18px;
}

.design-sizes .design-sizes-name-box {
  padding: 64px 0;
  padding-left: 60px;
  padding-right: 60px;
}

.design-sizes .design-sizes-name-box .single-design-sizes-name-box+.single-design-sizes-name-box {
  margin-top: 20px;
}

.design-sizes .design-sizes-name-box .single-design-sizes-name-box span.name {
  color: #747474;
  font-size: 16px;
  float: left;
}

.design-sizes .design-sizes-name-box .single-design-sizes-name-box span.size {
  color: #747474;
  font-size: 16px;
  float: right;
}

/************************** 
* 6. what we do styles 
***************************/
.what-we-do .single-what-we-do .text-box {
  background: #F5F6FA;
  border-radius: 10px;
  position: relative;
  margin-top: 10px;
  padding: 0 40px;
  padding-top: 45px;
  padding-bottom: 43px;
}

.what-we-do .single-what-we-do .text-box h3 {
  font-size: 30px;
  border-radius: 50px;
  font-weight: bold;
  margin-right: 2px;
  margin-bottom: 32px;
  color: #202020;
  transition: all .4s ease;
}

.what-we-do .single-what-we-do .text-box:hover h3,
.what-we-do .single-what-we-do .text-box:hover p {
  color: #fff;
}

.what-we-do .single-what-we-do .text-box a.read-more {
  background: var(--cf-theme-primary);
  color: #fff;
  font-size: 20px;
  line-height: 42px;
  border-radius: 10px;
  text-align: center;
  position: absolute;
  bottom: -21px;
  padding: 10px;
  right: 30px;
  transition: all .4s ease;
}

.what-we-do .single-what-we-do-two .img-box {
  float: right;
  width: 210px;
  height: 164px;
}

.what-we-do .single-what-we-do-two .text-box {
  color: #202020;
}


@media (max-width: 480px) {
  .service-tab-box .tab-title {
    width: auto !important;
  }

  .service-tab-box .tab-title ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  .service-tab-box .tab-title li {
    vertical-align: middle;
    width: 100%;
  }

  .what-we-do .single-what-we-do-two .img-box {
    width: 100%;
    height: auto;

  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .service-tab-box .tab-title {
    width: auto !important;
  }

  .service-tab-box .tab-title ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  .service-tab-box .tab-title li {
    vertical-align: middle;
    width: 30%;
  }
}




.hvr-bounce-to-bottom {
  background-size: 200% 200% !important;
  transition: background-position 0.3s !important;
  background-image: linear-gradient(to bottom, #ed6d1d 50%, #F5F6FA 0) !important;
  background-position: bottom !important;
}

.hvr-bounce-to-bottom.active {
  background: #ed6d1d !important;

}

.hvr-bounce-to-bottom.active h3 {
  color: #fff !important;

}

.hvr-bounce-to-bottom.active i {
  color: #fff !important;

}


.hvr-bounce-to-bottom:hover {
  background-position: top !important;
}


.btn-swipper {
  background: #f8f8f8;
  color: #555;
  border-radius: 48px;
  border-color: transparent !important;

}

.swipper-btn {
  background: #ed6d1d !important;
  color: #fff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: none;
}

.btn-swipper.active {
  background: #ed6d1d !important;
  color: #fff;
  border-radius: 48px;
  border-color: transparent !important;

}

.btn-swipper:hover {
  background: #ed6d1d;
  color: #fff;
}

.btn-swipper:focus {
  background: #ed6d1d;
  color: #fff;
}

.btn-swipper .active {
  background: #ed6d1d;
  color: #fff;
  border-radius: 48px;
}


@media (max-width: 480px) {
  .swiper-row {
    overflow-x: auto;
    gap: 20px;
    justify-content: space-between !important;
  }

  .swiper-row .btn-swipper {
    font-size: 0.8em;
    margin-right: 0 !important;
  }

  .swiper-slide-row {
    justify-content: unset !important;
  }

  .swiper-slide-row p {
    font-size: 0.8em;
  }

  .swipper-nav {
    display: none !important;
  }

  .img-aset {
    width: 100% !important;
  }

  .single-what-we-do {
    margin-bottom: 50px;
  }

  .single-what-we-do-two .img-box {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .swiper-row {
    overflow-x: auto;
    gap: 20px;
    justify-content: space-between !important;
  }

  .swiper-row .btn-swipper {
    font-size: 0.8em;
    margin-right: 0 !important;
  }

  .swiper-slide-row {
    justify-content: unset !important;
  }

  .swiper-slide-row p {
    font-size: 0.8em;
  }

  .swipper-nav {
    display: none !important;
  }

  .img-aset {
    width: 100% !important;
  }
}